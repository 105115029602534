export default {
  proxy_path: "https://jk8urv4bif.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://iykt8p8ikg.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4",
  main_company_term_id: "6",
  crm_base_url: "demo.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.demo.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.demo.forwoodsafety.com",
    APP_CLIENT_ID: "8p7d1t6mvq9l47gu8fgpdfrev",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://9hash1pqj8.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.demo.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
